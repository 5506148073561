import React from "react"
import Card from "../Card"
import TryButton from "../TryButton"
import TwitterPost from "../Twitts/TwitterPost"
import cashGame from "../../assets/images/Cards/cash-game-masterclass.jpg";
import gershonWins from "../../assets/images/Cards/gershon-wins.jpg";
import gershonWins2 from "../../assets/images/Cards/gershon-wins-2.jpg";
import * as cl from "./CardsCourses.module.css"

const CardsCourses = () => {
  return (
    <section className={cl.cards}>
      <div className={cl.content}>
        <h2 className={cl.mainTitle}>Advanced<br />poker courses</h2>

        <h3 className={cl.title}>Jonathan Little’s<br />Tournament Masterclass</h3>

        <Card
          flipped
          media={
            <div
              className="video-container"
              dangerouslySetInnerHTML={{__html: `
              <smartvideo
                src="https://jlsecrets.s3.amazonaws.com/pc/jl-quiz-walkthru-short.mp4"
                class="swarm-fluid"
                poster="https://jlsecrets.s3.amazonaws.com/pc/jl-quiz-walkthru-thumb.jpg"
                id="card_video_1"
                onplay="window.videoDATA.trackingVideo('PLAY', 'card_video_1')"
                onpause="window.videoDATA.trackingVideo('PAUSE', 'card_video_1')"
                onended="window.videoDATA.trackingVideo('END', 'card_video_1')"
                controls
                />
            `}}
            />
          }
          content={
            <>
              <p
                data-sal="fade"
                data-sal-duration="500"
                data-sal-easing="ease-in"
              >
                In this 180-lesson Masterclass, Jonathan Little teaches you everything you need to know to play every stage of a poker tournament optimally, maximally exploit your opponents, and maximize your tournament equity.
              </p>
              <TryButton source="6-jl-tournament-masterclass" />
            </>
          }
        />

        <Card
          size="small"
          media={
            <>
              <img src={gershonWins} alt="" />
              <TwitterPost
                size="big"
                user={{
                  name: 'Gershon Distenfeld',
                  login: '@GDistenfeld'
                }}
                post={{
                  text: '<p><small>Replying to <i>@cardplayerlife</i></small></p><p>I wouldn\'t have won my WSOP bracelet without <i>@PokerCoaching_</i> Great investment for any poker player!</p>'
                }}
                footer={{
                  time: '5:14 AM - Dec 28, 2021'
                }}
              />
            </>
          }
          content={
            <div
              data-sal="fade"
              data-sal-duration="500"
              data-sal-easing="ease-in"
            >
              <h3>
                Gershon Distenfeld Wins First Bracelet and $204,063 for Charity in Event #48: $1,500 Shootout
              </h3>
              <p>
                On the third and final day of the 2021 World Series of Poker Event #48: $1,500 Shootout No-Limit Hold'em, a champion was crowned as Gershon Distenfeld defeated Johan Schumacher after a back-and-forth heads-up battle for $204,063 that lasted over three hours.
              </p>
              <h3>
                Event #48: $1,500 Shootout No-Limit Hold'em Final Table Results
              </h3>
              <img src={gershonWins2} alt="" />
            </div>
          }
        />

        <h2 className={cl.title}>Jonathan Little’s All-New Cash Game Masterclass</h2>

        <Card
          media={
            <img src={cashGame} alt="" />
          }
          content={
            <>
              <p
                data-sal="fade"
                data-sal-duration="500"
                data-sal-easing="ease-in"
              >
                In this 156-lesson masterclass, Jonathan Little teaches you everything you need to know to crush small and medium stakes cash games!
              </p>
              <TryButton source="7-jl-all-new-cash-game-masterclass" />
            </>
          }
        />

        <TwitterPost
          size="big"
          className={cl.twitterPost}
          user={{
            name: '(((Joel)))',
            login: '@J__O__E__L'
          }}
          post={{
            text: '<p>Learning so much doing <i>@PokerCoaching_</i>\'s Cash Game MasterClass. Been playing poker 13+ years and never really grasped post-flop bet sizing. Now I know when, why, and how much to bet post-flop. Looking forward to putting this to use at the felt!</p>'
          }}
          footer={{
            time: '5:55 AM - Dec 23, 2021'
          }}
        />
      </div>
    </section>
  )
}

export default CardsCourses
