import React from "react"
import TwitterPost from "./TwitterPost"
import twitterPosts from "../../data/twitterPosts.json"
import * as cl from "./Twitts.module.css"

function getPosts(side){
  return twitterPosts.map((item) => {
    return item.position === side ? <TwitterPost key={item.id} className={cl.post} {...item} /> : null
  });
}

const Twitts = () => {
  const leftColumn = getPosts("left")
  const rightColumn = getPosts("right")

  return (
    <section className={cl.twitter}>
      <div className={cl.content}>
        <h2 className={cl.title}>
          Our members<br />are <span>crushing it!</span>
        </h2>

        <div className={cl.twitter__messages}>
          <div>{leftColumn}</div>
          <div className={cl.mobileHidden}>{rightColumn}</div>
        </div>
      </div>
    </section>
  )
}

export default Twitts
