// extracted by mini-css-extract-plugin
export var close__quote = "Quickly-module--close__quote--VZZ1Z";
export var content = "Quickly-module--content--rlcdr";
export var content__header = "Quickly-module--content__header--P52z1";
export var content__info = "Quickly-module--content__info--zZvDz";
export var description = "Quickly-module--description--b2F-v";
export var mark = "Quickly-module--mark--po4w2";
export var movie = "Quickly-module--movie--85iMT";
export var open__quote = "Quickly-module--open__quote--T9co+";
export var quickly = "Quickly-module--quickly--EcncY";
export var start__button = "Quickly-module--start__button--jJYZg";
export var sup__button = "Quickly-module--sup__button--CB5KC";
export var title = "Quickly-module--title--VZoKs";
export var video = "Quickly-module--video--t3qr5";
export var video__play = "Quickly-module--video__play--sorIv";
export var video__wrapper = "Quickly-module--video__wrapper--uX-JB";