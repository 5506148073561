import React from "react"
import * as style from "/src/components/SimpleGrayStr/SimpleGrayStr.module.css"

const SimpleGrayStr = () => {
  return (
    <section className={style.wrapper}>
      <strong className={style.strong}>PokerCoaching</strong>— enabling Poker
      Players to increase their win rate through Active Learning
    </section>
  )
}

export default SimpleGrayStr
