const indicatorNumbers = [
  {
    number: 120061,
    title: "Poker Players",
    prefix: "",
    postfix: "",
  },
  {
    number: 5734,
    title: "Community Members",
    prefix: "",
    postfix: "",
  },
  {
    number: 6542092,
    title: "Quizzes Taken",
    prefix: "",
    postfix: "",
  },
  {
    number: 57000000,
    title: "Coach Earnings",
    prefix: "$",
    postfix: "",
  },
]

export default indicatorNumbers
