import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import TryButton from "../TryButton"
import * as cl from "./CoachQuote.module.css"

const CoachQuote = () => {
  return (
    <div className={cl.coach}>
      <div className={cl.coach__text}>
        <figure>
          <blockquote className={cl.coach__quote}>
            <p>
              “I paid my team of world-class coaches <b>over $650,000</b> (so far) to create hundreds of coaching webinars and interactive hand quizzes that you can access right now inside PokerCoaching.com.
            </p>
            <p>
              I continue to pay our coaches <b>5 figures EVERY MONTH</b> to host live weekly coaching webinars and create new interactive hand quizzes for our members!”
            </p>
            <figcaption>
              Jonathan Little<br />Founder of PokerCoaching.com
            </figcaption>
          </blockquote>
        </figure>

        <TryButton source="2-jl-quote" />
      </div>

      <div className={cl.coach__image}>
        <StaticImage
          src="../../assets/images/MainPage/top-level-coach.png"
          alt="Top-level coach Jonathan Little"
          placeholder="blurred"
          imgStyle={{
            objectFit: "contain",
          }}
        />
      </div>
    </div>
  )
}

export default CoachQuote
