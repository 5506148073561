import React from "react"
import * as cl from "/src/components/ActiveLearning/ActiveLearning.module.css"
import Image from "../Image"
import bookImg from "../../assets/images/MainPage/active-book.svg"


const ActiveLearning = () => {
  return (
    <section className={cl.active}>
      <div className={cl.content}>
        <h2 className={cl.title}>What's Active Learning...?</h2>
        <p className={cl.description}>
          What exactly is the difference between Passive Learning and Active
          Learning
        </p>

        <div className={cl.blocks}>
          <article
            className={cl.block}
            data-sal="slide-down"
            data-sal-duration="500"
            data-sal-easing="ease"
          >
            <img
              className={cl.block__image}
              src={bookImg}
              alt="Passive Learning book"
            />
            <h3 className={cl.block__title}>Passive Learning</h3>
            <p className={cl.block__text}>
              is watching a video and passively
              <br />
              listening to a coach tell you
              <br />
              how to play a spot ...
            </p>
            <p className={cl.block__postscript}>... and hoping you remember.</p>
          </article>

          <article
            className={cl.block}
            data-sal="slide-down"
            data-sal-duration="500"
            data-sal-easing="ease"
          >
            <Image
              className={`${cl.block__image} ${cl.block__image__salute}`}
              src={"MainPage/active-salute.svg"}
              alt="Active Learning salute"
            />
            <h3 className={cl.block__title}>Active Learning</h3>
            <p className={cl.block__text}>
              is <b>Actually Playing</b> a spot and getting
              <br />
              <b>Instant Feedback</b> from an elite
              <br />
              PokerCoaching.com coach.
            </p>
            <p className={`${cl.block__text} ${cl.hide}`}>
              Active Learning helps you discover <br />
              your leaks and fix them <b>INSTANTLY!</b>
            </p>
          </article>
        </div>

        <div className={cl.video}>
          <div
            className="video-container"
            dangerouslySetInnerHTML={{__html: `
              <smartvideo
                src="https://jlsecrets.s3.amazonaws.com/pc/ActiveLearning-11.06.mp4"
                poster="http://jlsecrets.s3.amazonaws.com/pc/active-learning-poster.jpg"
                class="swarm-fluid"
                id="active_learning_video"
                onplay="window.videoDATA.trackingVideo('PLAY', 'active_learning_video')"
                onpause="window.videoDATA.trackingVideo('PAUSE', 'active_learning_video')"
                onended="window.videoDATA.trackingVideo('END', 'active_learning_video')"
                controls
              />
            `}}
          />
        </div>
      </div>
    </section>
  )
}

export default ActiveLearning
