import React from "react"
import Shark from "../Shark"
import TryButton from "../TryButton"
import * as cl from "./BeShark.module.css"

const BeShark = () => {
  return (
    <div className={cl.wrapper}>
      <div className={cl.content}>
        <Shark />
        <TryButton dark source="11-site-bottom" />
      </div>
    </div>
  )
}

export default BeShark
