import React from "react"
import AppLink from "../AppLink"
import classnames from "classnames"
import * as cl from "./TryButton.module.css"
import analytics from '/src/utils/tracking'

const onClick = (source) => {
  analytics.track('home:button:click', {
    ref: source
  });
}

const TryButton = ({ dark, className, source }) => {
  return (
    <div className={classnames(cl.wrapper, { [cl.black]: dark }, className)} onClick={() => onClick(source)}>
      <AppLink to="/pricing/" className={cl.button}>Try for free</AppLink>
      <p className={cl.note}>No Credit Card Required.</p>
    </div>
  )
}

export default TryButton
