import React from "react"
import Card from "../Card"
import Shark from "../Shark"
import TryButton from "../TryButton"
import tournamentChallenge from "../../assets/images/Cards/30-day-tournament-challenge.jpg";
import cashGameChallenge from "../../assets/images/Cards/30-day-cash-game-challenge.jpg";
import * as cl from "../Cards/Cards.module.css"

const CardsChallenge = () => {
  return (
    <section className={cl.cards}>
      <div className={cl.content}>
        <h2 className={cl.mainTitle}>30-day challenges</h2>

        <h2 className={cl.title}>The PokerCoaching Premium<br />  30-Day Tournament Challenge</h2>

        <Card
          flipped
          media={
            <img src={tournamentChallenge} alt="" />
          }
          content={
            <>
              <p
                data-sal="fade"
                data-sal-duration="500"
                data-sal-easing="ease-in"
              >
                Each day, you'll watch a video from one of our world-class coaches and take a daily quiz to make sure you're learning the key concepts. This challenge will fundamentally change the way you play poker tournaments!
              </p>
              <TryButton source="8-30-day-tournament-challenge" />
            </>
          }
        />

        <h2 className={cl.title}>The PokerCoaching Premium<br />30-Day Cash Game Challenge</h2>

        <Card
          media={
            <img src={cashGameChallenge} alt="" />
          }
          content={
            <>
              <p
                data-sal="fade"
                data-sal-duration="500"
                data-sal-easing="ease-in"
              >
                This challenge covers all the topics you need to master to develop a solid winning strategy in cash games in just 30 days.
              </p>
              <TryButton className={cl.mobileHidden} source="9-30-day-cash-game-challenge" />
            </>
          }
        />
      </div>

      <footer className={cl.footer}>
        <Shark />
        <TryButton source="10-challenges-section-bottom" />
      </footer>
    </section>
  )
}

export default CardsChallenge
