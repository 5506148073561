import React from "react"
import * as cl from "/src/components/IndicatorNumbers/IndicatorNumbers.module.css"
import CountUp from "react-countup"

const IndicatorNumbersItem = ({ number, title, prefix, postfix }) => {
  return (
    <li className={cl.data__item}>
      <div className={cl.data__number}>
        <CountUp
          start={Math.floor(number / 2)}
          end={number}
          duration={3}
          delay={2}
          separator=","
          prefix={prefix}
          suffix={postfix}
        />
      </div>
      <h3 className={cl.data__description}>{title}</h3>
    </li>
  )
}

export default IndicatorNumbersItem
