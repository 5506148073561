import React from "react"
import LogoShark from '../../assets/images/logo-shark.png';
import * as cl from "./Shark.module.css"

const Shark = () => {
  return (
    <div className={cl.logo}>
      <img src={LogoShark} alt="" className={cl.img} />
      <span>Be the shark</span>
    </div>
  )
}

export default Shark
