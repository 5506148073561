// extracted by mini-css-extract-plugin
export var active = "ActiveLearning-module--active--tlTsh";
export var block = "ActiveLearning-module--block--mrVCv";
export var block__image = "ActiveLearning-module--block__image--dTPeE";
export var block__image__salute = "ActiveLearning-module--block__image__salute--p2fgp";
export var block__postscript = "ActiveLearning-module--block__postscript--dv5XC";
export var block__text = "ActiveLearning-module--block__text--58kFi";
export var block__title = "ActiveLearning-module--block__title--OreD2";
export var blocks = "ActiveLearning-module--blocks--Szz7s";
export var content = "ActiveLearning-module--content--s2GtK";
export var description = "ActiveLearning-module--description--Etux1";
export var hide = "ActiveLearning-module--hide--ieyGs";
export var title = "ActiveLearning-module--title--OyAzS";
export var video = "ActiveLearning-module--video--gvwpg";