import React from "react"
import classnames from "classnames"
import Shark from "../Shark"
import TryButton from "../TryButton"
import * as cl from "/src/components/HowItWorks/HowItWorks.module.css"

const HowItWorks = () => {
  return (
    <section className={cl.how}>
      <div className={cl.content}>
        <h2 className={cl.title}>
          Built from day 1 to help you leverage Active Learning.
        </h2>
        <p className={cl.description}>
          PokerCoaching uses Active Learning to help you quickly master the game so you can achieve your full poker potential.
        </p>
        <p className={classnames(cl.description, cl.description_additional)}>
          Interactive Hand Quizzes let you "play" thousands of real world spots and get INSTANT FEEDBACK from elite coaches so you can discover your leaks and fix them INSTANTLY!
        </p>
        <Shark />
        <TryButton dark source="1-active-learning" />
      </div>
    </section>
  )
}

export default HowItWorks
