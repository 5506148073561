import * as React from "react"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import SimpleGrayStr from "../components/SimpleGrayStr"
import Quickly from "../components/Quickly"
import IndicatorNumbers from "../components/IndicatorNumbers"
import ActiveLearning from "../components/ActiveLearning"
import HowItWorks from "../components/HowItWorks"
import Coaches from "../components/Coaches"
import Cards from "../components/Cards"
import CardsCourses from "../components/CardsCourses"
import CardsChallenge from "../components/CardsChallenge"
import Twitts from "../components/Twitts"
import BeShark from "../components/BeShark"
import Footer from "../components/Footer"

const IndexPage = () => {
  return (
    <Layout innerPage={false}>
      <Seo title="PokerCoaching.com" />
      <SimpleGrayStr />
      <Quickly />
      <IndicatorNumbers />
      <ActiveLearning />
      <HowItWorks />
      <Coaches />
      <Cards />
      <CardsCourses />
      <CardsChallenge />
      <Twitts />
      <BeShark />
      <Footer />
    </Layout>
  )
}

export default IndexPage
