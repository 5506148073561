import React from "react"
import CoachQuote from "../CoachQuote/CoachQuote"
import logo from "../../assets/images/logo-2.png";
import coaches from "../../assets/images/coaches/16-coaches-62m.jpg";
import * as cl from "/src/components/Coaches/Coaches.module.css"

const Coaches = () => {
  return (
    <section className={cl.coaches}>
      <div className={cl.content}>
        <h2 className={cl.title}>
          <span>Access over</span>
          <span className={cl.title__price}>$650,000</span>
          <span className={cl.title__of}>worth of poker training</span>
          <span className={cl.title__from}>from Our Team of World-Class Poker Pros Inside</span>
        </h2>

        <img src={logo} alt="PokerCoaching.com" className={cl.logo} />

        <div className={cl.coachesImg}>
          <img src={coaches} alt="" className={cl.coachesImg__img} />
        </div>

        <CoachQuote />
      </div>
    </section>
  )
}

export default Coaches
