import React, { useRef, useEffect } from "react"
import posterImg from "../../assets/images/MainPage/video-poster.jpeg"
import TryButton from "../TryButton"
import * as cl from "/src/components/Quickly/Quickly.module.css"
import analytics from "/src/utils/tracking"


const Quickly = () => {
  const ref = useRef(null);
  const isFirstPlay = useRef(true);
  const isPLay = useRef(false);

  const removeBtn = () => {
    const btn = document.querySelector('#video-wrapper').children[1]
    btn.remove()
  }

  const addBtn = () => {
    const wrap = document.querySelector('#video-wrapper')

    let btnJS = document.createElement('BUTTON')
    btnJS.className = cl.video__play
    btnJS.onclick = e => {onPlayClick(e)}
    btnJS.ariaLabel = "Play"

    wrap.append(btnJS)
  }

  const onPlayClick = (e) => {
    e.preventDefault()

    isPLay.current ? ref.current.pause() : ref.current.play()
    isPLay.current = !isPLay.current

    if (!isPLay.current) addBtn()
    if (isPLay.current) removeBtn()

    if (isFirstPlay.current) {
      ref.current.currentTime = 0
      // ref.current.volume = 1
      ref.current.muted = false
      isPLay.current = true
      isFirstPlay.current = false
    }

    // trackingVideo(e)

    analytics.track('home:video:click', {
      title: 'hero-video'
    });

  }


  useEffect(() => {
    setTimeout(() => {
      ref.current = document.querySelector('#hero_video')

      ref.current.addEventListener('click', (e) => {
        onPlayClick(e)
      })

      setTimeout(() => {
        ref.current.play()
        ref.current.controls = 'controls'
      }, 2500)
    })
  }, [])

  const goAnchor = () => {
    const wr = document.querySelector('#plans-section')
    window.scroll({ top: wr.getBoundingClientRect().top + window.pageYOffset, behavior: "smooth" })
  }


  const title = (
    <h2 className={cl.title}>
      Achieve Your Full Poker Potential with
      <br />
      <span className={cl.mark}>
        Active Learning
      </span>
    </h2>
  )

  return (
    <section className={cl.quickly}>
      <article className={cl.content}>
        <div
          className={cl.content__info}
          data-sal="slide-right"
          data-sal-duration="300"
          data-sal-easing="ease"
        >
          <header className={cl.content__header}>
            {title}
            <p className={cl.description}>
              Without Having to Hire<br />an Expensive Coach!
            </p>
          </header>

          <TryButton source="0-hero" />
        </div>

        <article className={cl.video__wrapper}>
          <div
            className={cl.video__wrapper}
            id='video-wrapper'
          >
            <div
              dangerouslySetInnerHTML={{ __html: `
                <video
                  poster="${posterImg}"
                  class="${cl.movie}"
                  width="100%" height="100%"
                  preload="auto"
                  muted
                  playsinline

                  id="hero_video"
                  onplay="window.videoDATA.trackingVideo('PLAY', 'hero_video')"
                  onpause="window.videoDATA.trackingVideo('PAUSE', 'hero_video')"
                  onended="window.videoDATA.trackingVideo('END', 'hero_video')"
                >
                  <source src='https://pokercoaching.com/pc_assets/videos/websiteVideo.mp4' type='video/mp4'/>
                  <source src='https://pokercoaching.com/pc_assets/videos/websiteVideo.webm' type='video/webm'/>
                  <source src='https://pokercoaching.com/pc_assets/videos/websiteVideo.mov' type='video/mov'/>
                  Your browser does not support the video tag.
                </video>
            ` }}
            />

            {!isPLay.current && <button className={cl.video__play} aria-label="Play" onClick={e => onPlayClick(e)} />}

          </div>
        </article>
      </article>
    </section>
  )
}

export default Quickly
