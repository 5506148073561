import React from "react"
import Card from "../Card"
import Shark from "../Shark"
import TryButton from "../TryButton"
import logo from "../../assets/images/logo-3.png";
import TwitterPost from "../Twitts/TwitterPost"
import coaches from "../../assets/images/coaches/16-coaches-blue.jpg";
import * as cl from "./Cards.module.css"

const Cards = () => {
  return (
    <section className={cl.cards}>
      <div className={cl.content}>
        <img
          className={cl.logo}
          src={logo}
          alt="Pokercoaching.com"
        />

        <h2 className={cl.title}>“Play” Thousands of Interactive Hand Quizzes</h2>

        <Card
          flipped
          media={
            <div
              className="video-container"
              dangerouslySetInnerHTML={{__html: `
              <smartvideo
                src="https://jlsecrets.s3.amazonaws.com/pc/jl-quiz-walkthru-short.mp4"
                class="swarm-fluid"
                poster="https://jlsecrets.s3.amazonaws.com/pc/jl-quiz-walkthru-thumb.jpg"
                id="card_video_1"
                onplay="window.videoDATA.trackingVideo('PLAY', 'card_video_1')"
                onpause="window.videoDATA.trackingVideo('PAUSE', 'card_video_1')"
                onended="window.videoDATA.trackingVideo('END', 'card_video_1')"
                controls
                />
            `}}
            />
          }
          content={
            <>
              <p
                data-sal="fade"
                data-sal-duration="500"
                data-sal-easing="ease-in"
              >
                Interactive Hand Quizzes let you "play" thousands of real world spots and get INSTANT FEEDBACK from elite coaches so you can discover your leaks and fix them INSTANTLY!
              </p>
              <TryButton source="3-quiz-section-top" />
            </>
          }
        />

        <TwitterPost
          size="big"
          className={cl.twitterPost}
          user={{
            name: 'Casey Padilla',
            login: '@Casey_J_Padilla'
          }}
          post={{
            text: '<p><i>@PokerCoaching_</i> These quizzes are awesome! Found a leak in my game and now know how to adjust my play in sticky situations. <i>#Knowledgeispower</i></p>'
          }}
        />

        <h2 className={cl.title}>Live Weekly Coaching with World-Class Coaches</h2>

        <Card
          media={
            <img src={coaches} alt="" />
          }
          content={
            <>
              <p
                data-sal="fade"
                data-sal-duration="500"
                data-sal-easing="ease-in"
              >
                Attend live weekly coaching webinars with world-class coaches with over $56 million in combined earnings! Premium members get access to 500+ coaching webinars on a variety of topics!
              </p>
              <TryButton className={cl.mobileHidden} source="4-world-class-coaches" />
            </>
          }
        />
      </div>

      <footer className={cl.footer}>
        <Shark />
        <TryButton source="5-quiz-section-bottom" />
      </footer>
    </section>
  )
}

export default Cards
